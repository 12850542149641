<template>
  <div class="card">
    <template v-for="(node, index) in computedData">
      <AccordionTree
        class="card"
        v-bind:data="node"
        v-bind:node-index="index"
        v-bind:key="node.name"
        v-on:get-children="getChildren"
        v-on:show-delivery-times="showDeliveryTimes"
        v-on:on-switch="onSwitch"
      />
    </template>
  </div>
</template>
<script>
import AccordionTree from "./components/AccordionTree/Index";

export default {
  name: "Accordion",
  components: {
    AccordionTree,
  },
  props: {
    data: {
      default: () => [],
    },
  },
  model: {
    prop: "data",
  },
  methods: {
    updateList() {
      let data = {
        sort: this.filter.sorting.id,
        search:
          this.filter.search && this.filter.search.length > 2
            ? this.filter.search
            : null,
      };
      this.$emit("update-list", data);
    },
    getChildren(node) {
      this.$emit("get-children", node);
    },
    showDeliveryTimes(node) {
      this.$emit("show-delivery-times", node);
    },
    onSwitch(data) {
      this.$emit("on-switch", data);
    },
  },
  computed: {
    computedData() {
      return this.data;
    },
  },
};
</script>