<template>
  <div class="accordion card border-0 my-1" v-bind:id="componentId">
    <div class="card-body py-2 hover-shadow" v-bind:id="headingId">
      <div class="row align-items-center">
        <div
          class="col btn btn-block text-left"
          data-toggle="collapse"
          aria-expanded="true"
          v-bind:data-target="'#' + collapseId + '-' + nodeIndex"
          v-bind:aria-controls="collapseId + '-' + nodeIndex"
          v-on:click="getChildren"
        >
          <template v-if="getParents.length">
            <div
              class="d-inline-block"
              v-for="(parent, parentIndex) in getParents"
              v-bind:key="parent.id + '-' + parentIndex"
            >
              <span class="font-weight-bold">
                {{ parent.name }}
              </span>
              <i class="fas fa-chevron-right mx-3"></i>
            </div>
          </template>
          <span
            v-bind:class="{
              'font-weight-bold': node.children,
            }"
            >{{ node.name }}
            <i
              v-if="node.loading"
              class="fas fa-spinner text-indigo fa-spin ml-2"
            ></i
          ></span>
        </div>
        <div class="col-auto">
          <div class="row align-items-center">
            <div
              class="col-auto pr-0"
              v-if="node.hasOwnProperty('delivery_times')"
            >
              <span
                style="min-width: 33px"
                v-bind:class="[
                  'btn btn-sm',
                  checkDeliveryTimes ? 'btn-success' : 'btn-indigo',
                ]"
                v-tooltip.bottom="{
                  content: checkDeliveryTimes
                    ? 'Teslimat Saatleri'
                    : 'Teslimat Saati Ekle',
                }"
                v-on:click="showDeliveryTimes"
                ><i
                  v-bind:class="[
                    checkDeliveryTimes ? 'fas fa-clock' : 'far fa-clock',
                  ]"
                ></i
              ></span>
            </div>
            <div class="col-auto">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  v-bind:id="componentId + '-value'"
                  v-bind:checked="node.is_active"
                  v-on:change="setSwitchStatus"
                />
                <label
                  class="custom-control-label"
                  v-bind:for="componentId + '-value'"
                >
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="node.children && node.children.length"
      v-bind:id="collapseId + '-' + nodeIndex"
      class="collapse"
      v-bind:class="{
        show: node.children && node.children.length && node.show,
      }"
      v-bind:aria-labelledby="headingId"
      v-bind:data-parent="'#' + componentId"
    >
      <template v-for="(children, index) in node.children">
        <AccordionTree
          class="card"
          v-bind:data="children"
          v-bind:node-index="index"
          v-bind:key="children.name"
          v-bind:parents="[...parents, node]"
          v-on:get-children="getChildren"
          v-on:show-delivery-times="showDeliveryTimes"
          v-on:on-switch="onSwitch"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "AccordionTree",
  props: {
    data: {
      default: null,
    },
    nodeIndex: {
      default: 0,
    },
    parents: {
      default: () => [],
    },
  },
  methods: {
    getChildren() {
      if (this.node.children && !this.node.children.length) {
        if (this.node.loading) return;

        const data = {
          node: this.node,
          parents: this.getParents,
        };

        const eventProp = {
          event: "get-children",
          payload: data,
        };

        this.emitEvent(eventProp);
      }
    },
    showDeliveryTimes() {
      if (this.node.loading) return;

      const data = {
        node: this.node,
        parents: this.getParents,
      };

      const eventProp = {
        event: "show-delivery-times",
        payload: data,
      };

      this.emitEvent(eventProp);
    },
    emitEvent(data) {
      switch (this.getParents.length) {
        case 1:
          this.$parent.$emit(data.event, data.payload);
          break;

        case 2:
          this.$parent.$parent.$emit(data.event, data.payload);
          break;

        case 3:
          this.$parent.$parent.$parent.$emit(data.event, data.payload);
          break;

        default:
          this.$emit(data.event, data.payload);
          break;
      }
    },
    onSwitch(data) {
      if (this.getParents.length == 1) {
        this.$parent.$emit("on-switch", data);
      } else if (this.getParents.length == 2) {
        this.$parent.$parent.$emit("on-switch", data);
      } else {
        this.$emit("on-switch", data);
      }
    },
    setSwitchStatus(e) {
      let data = {
        node: this.node,
        value: e.target.checked,
        parents: this.getParents,
      };
      this.onSwitch(data);
    },
  },
  computed: {
    componentId() {
      return "accordion-" + helper.getRandomInteger(1, 999999);
    },
    headingId() {
      return this.componentId + "-heading";
    },
    collapseId() {
      return this.componentId + "-collapse";
    },
    getParents() {
      return this.parents && this.parents.length
        ? this.parents
            .filter((item) => item.children.length)
            .map((parent) => {
              return {
                id: parent.id,
                name: parent.name,
                show: parent.show,
                delivery_times: parent.delivery_times,
              };
            })
        : [];
    },
    node() {
      return this.data;
    },
    checkDeliveryTimes() {
      return this.node.delivery_times && this.node.delivery_times.length;
    },
  },
};
</script>